import { createApp } from "vue";
import "video.js/dist/video-js.css";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import "./assets/css/index.scss";
import { registerIcon } from "@/assets/svg/index";
// mock 数据
// import "@/data/mock/api";
import "element-plus/theme-chalk/el-message.css";
import "element-plus/theme-chalk/el-message-box.css";
import "element-plus/theme-chalk/el-loading.css";
import "element-plus/theme-chalk/el-overlay.css";

const app = createApp(App);
// app.config.globalProperties.$ELEMENT = { size: "small" };
registerIcon(app);
app.use(store).use(router).mount("#app");

// createApp(App).use(store).use(router).mount("#app");
