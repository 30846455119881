import { DiyRouteRecordRaw } from "../routes";

const routes: Array<DiyRouteRecordRaw> = [
  {
    path: "/login",
    name: "Login",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "login" */ "@/pages/login/Login.vue"),
  },
];
export default routes;
