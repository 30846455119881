import httpClient from "./httpClient";
import { ApiResponse } from "@/@types/index";
import { DictCode } from "@/configs/const/app.const";

export interface DictBean {
  /** 字典编码	 */
  dictCode: string;
  /** 字典名称	 */
  dictName: string;
  /** 字典类型列表 */
  dictType: DictItemBean[];
}
export interface DictItemBean {
  /** 类型值 */
  typeCode: string;
  /** 类型值名 */
  typeName: string;
}

/**
 * 获取业务真正要用的数据字典
 * @param data
 * @returns
 */
export function getDictList(
  data: Record<string, any> = {}
): Promise<ApiResponse<DictBean[]>> {
  const code = Object.values(DictCode).join(",");
  return httpClient.get(`/sys/dictList?code=${code}`);
}
