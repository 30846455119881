import { RouteRecordRaw } from "vue-router";
// import Home from '../views/Home.vue'
// import Api from '../views/Api.vue'
import loginRoutes from "./modules/login.router";
import mainRoutes from "./modules/main.router";
import MainLayout from "@/layouts/main/MainLayout.vue";

export interface DiyRouteRecordRaw extends Omit<RouteRecordRaw, "children"> {
  /** 在menu中是否隐藏 */
  hideInMenu?: boolean;
  /** 在menu中隐藏子节点 */
  hideChildrenInMenu?: boolean;
  children?: DiyRouteRecordRaw[];
}

const baseRoutes: Array<DiyRouteRecordRaw> = [
  ...loginRoutes,
  {
    path: "/404",
    component: () => import(/* webpackChunkName: "fail" */ "@/pages/404.vue"),
  },
];
const routes: Array<DiyRouteRecordRaw> = [
  {
    path: "/",
    name: "Home",
    component: MainLayout,
    redirect: "/xr-store",
    children: [...mainRoutes],
  },
  ...baseRoutes,

  // ...prjRouter,
  // ...dvRouter,
  // ...sysRouter

  {
    path: "/:w+", // 此处需特别注意至于最底部
    redirect: "/404",
  },
];

export default routes;
