import httpClient from "./httpClient";
import { ApiResponse } from "@/@types/index";
export interface userInfoPowersMap {
  [key: string]: { name: string };
}
export interface userInfoRolesMap {
  [key: string]: { name: string };
}
// 用户信息
export interface UserInfo {
  /** token */
  token?: string;

  /** 用户ID */
  userId: string;
  /** 用户名	 */
  userName: string;
  /** 用户姓名	 */
  realName: string;
  /** 学生学号	 */
  studentNo: string;
  /** 学校名称 */
  userOrg: string;
  /** 用户类型 */
  userType: number;
  /** 用户头像 */
  portrait: string;
}
// {
//   loginName: string;
//   pwd: string;
// }
export function login(
  data: Record<string, any>
): Promise<ApiResponse<UserInfo>> {
  return httpClient.post("/sys/mLogin", data);
}

export function logout(): Promise<ApiResponse> {
  return httpClient.post(`/sys/mLogout`);
}
