import { Module } from "vuex";
import { StoreState } from "../types";
import { login, logout, UserInfo } from "@/services/login";
import { setUserInfo, clearUserInfo, getUserInfo } from "@/utils/authority";

export interface LoginModuleState {
  userInfo: UserInfo;
}

const user: Module<LoginModuleState, StoreState> = {
  namespaced: true,
  state: {
    userInfo: getUserInfo(),
  },
  getters: {},
  mutations: {
    setUserInfo(state, userInfo: UserInfo) {
      state.userInfo = userInfo;
    },
  },
  actions: {
    async login({ commit }, data: Record<string, any>) {
      const res = await login(data);
      console.log("getUserListgetUserListgetUserList", res);
      if (res.respCode === 200) {
        setUserInfo(res.data);
        commit("setUserInfo", res.data);
      }
      return res;
    },
    async logout({ commit }, data: Record<string, any>) {
      const res = await logout();
      console.log("注销操作");
      // if (res.respCode === 200) {
      // }
      clearUserInfo();
      commit("setUserInfo", {});
      return res;
    },
  },
};

export default user;
