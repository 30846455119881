import dayjs from "dayjs";
import { v4 as uuidv4 } from "uuid";

// 根据文件后缀获取文件格式类型，后缀不带点
export function getFileExt(fileName: string) {
  // TODO 这边需要加个保护，有的文件可能本来就没有点。 要做容错处理。
  return fileName.substring(fileName.lastIndexOf(".") + 1) || "";
}

export function getFileNameAndExt(fileName: string) {
  const name = fileName.substring(0, fileName.lastIndexOf(".")) || "";
  const ext = getFileExt(fileName);
  return {
    name,
    ext,
  };
}
//oss文件名获取随机
export const getRandomFileName = () => {
  return uuidv4();
};
export const getFilePathPrefix = () => {
  // const timeNumber = new Date().getTime();
  // const randomNumber = Math.floor(Math.random() * 999999);
  // return timeNumber + "" + randomNumber;
  const nowtime = dayjs();
  return nowtime.format("YYYY") + "/" + nowtime.format("MM");
};
export const getOssPrefix = (ext: string) => {
  let prefix = "file/other";
  ext = ext.toLowerCase();
  if (["jpg", "jpeg", "png", "gif", "bmp"].includes(ext)) {
    prefix = "img";
  } else if (
    [
      "mp4",
      "avi",
      "mov",
      "wmv",
      "flv",
      "f4v",
      "rmvb",
      "rm",
      "mkv",
      "3gp",
      "dat",
      "ts",
      "mts",
      "vob",
    ].includes(ext)
  ) {
    prefix = "video";
  } else if (
    ["mp3", "wav", "wma", "ogg", "aac", "flac", "ape", "amr"].includes(ext)
  ) {
    prefix = "audio";
  } else if (["fbx", "obj", "glb", "gltf"].includes(ext)) {
    prefix = "file/model";
  }

  return prefix;
};

export function getOssFilePath(fileName: string) {
  const { name, ext } = getFileNameAndExt(fileName);

  const filename = `${getRandomFileName()}.${ext}`;
  const prefix = getOssPrefix(ext);
  const filePath = `${prefix}/${getFilePathPrefix()}/${filename}`;

  return filePath;
}

export function getOssCallbackConfig(fileName: string) {
  const { name, ext } = getFileNameAndExt(fileName);

  // const uploadPath = getOssFilePath(fileName);
  let body =
    '{"bucket":${bucket},"object":${object},"etag":${etag},"mimeType":${mimeType},"size":${size},"originalFileName": ${x:originalFileName}}';
  if (["jpg", "jpeg", "png", "gif", "bmp"].includes(ext.toLowerCase())) {
    body =
      '{"bucket":${bucket},"object":${object},"etag":${etag},"mimeType":${mimeType},"size":${size},"imageInfoHeight": ${imageInfo.height},"imageInfoWidth": ${imageInfo.width},"imageInfo.format": ${imageInfoFormat},"originalFileName": ${x:originalFileName}}';
  }
  const customValue = {
    originalFileName: fileName,
  };
  return {
    url: "https://xrverse.touhaox.com/xrapi/app/oss/sts/callback",
    body,
    customValue,
    contentType: "application/json",
  };
}
// 接收一个扩展后缀，生成文件名称
// export function generateFileName(fileExt: string) {
//   if (fileExt) {
//     return uuidv4() + "." + fileExt;
//   }
//   return uuidv4();
// }
// 文件下载
// export const exportExcel = (data: BlobPart, name: string) => {
//   const blob = new Blob([data], {
//     type: 'application/vnd.ms-excel',
//     // type: 'application/octet-stream'
//   })
//   const downloadElement = document.createElement('a')
//   const href = window.URL.createObjectURL(blob) // 创建下载的链接
//   downloadElement.href = href
//   downloadElement.download = `${name}.xlsx` // 下载后文件名
//   document.body.appendChild(downloadElement)
//   downloadElement.click() // 点击下载
//   document.body.removeChild(downloadElement) // 下载完成移除元素
//   window.URL.revokeObjectURL(href) // 释放掉blob对象
// }

/**
 * 根据文件流另存为
 * @param  {Blob} blob 文件流
 * @param  {String} filename 想要保存的文件名称
 */
// export function downloadFileFromBlob(blob: Blob, filename: any) {
//   //@ts-ignore
//   if (window.navigator.msSaveOrOpenBlob) {
//     //@ts-ignore
//     navigator.msSaveBlob(blob, filename);
//   } else {
//     const downloadElement = document.createElement("a");
//     downloadElement.href = window.URL.createObjectURL(blob); // 创建下载的链接
//     downloadElement.download = filename; // 下载后文件名
//     // fix Firefox
//     downloadElement.style.display = "none";
//     document.body.appendChild(downloadElement);
//     downloadElement.click(); // 点击下载
//     document.body.removeChild(downloadElement); // 下载完成移除元素
//     window.URL.revokeObjectURL(downloadElement.href); // 释放掉blob对象
//   }
// }

/**
 * 下载:适用于下载指定url的文件重命名另存为
 * @param  {String} url 目标文件地址
 * @param  {String} filename 想要保存的文件名称
 */
interface DownloadFileOption {
  loadProgress?: (progress: number) => void;
}
// export function downloadFileFromUrl(
//   url: string,
//   filename: string,
//   option: DownloadFileOption = {}
// ) {
//   const { loadProgress } = option;
//   return axios
//     .get(url, {
//       responseType: "blob",
//       onDownloadProgress: (evt) => {
//         const progress = Number(
//           ((evt.loaded / evt.total) * 100).toFixed(2) || 0
//         );
//         loadProgress && loadProgress(progress);
//       },
//     })
//     .then((res) => {
//       downloadFileFromBlob(res.data as unknown as Blob, filename);
//     })
//     .catch(() => {
//       Message.error("下载失败！！！");
//     });
// }

// 只针对application/json类型的blob做如此操作
export function readFileToJson(blob: Blob) {
  return new Promise(function (resolve, reject) {
    const reader = new FileReader();
    reader.onload = function (event) {
      const content = reader.result;
      console.log(content);
      try {
        resolve(JSON.parse(content as string));
      } catch (e) {
        reject({ code: 500, message: "解析数据失败" });
      }
    };
    reader.onerror = function (error) {
      reject({ code: 500, message: "解析数据失败" });
    };
    reader.readAsText(blob);
  });
}
