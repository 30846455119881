import { Module } from "vuex";
import { StoreState } from "../types";
import { DictBean, getDictList } from "@/services/sys";
// import { getSortList } from "@/services/api";
// import { arr2tree } from "@/utils/fn";
// import { CategoryModel } from "@/services/entity";

export interface AppModuleState {
  dictMap: Record<string, DictBean>;
  // categoryTree: CategoryModel[];
}

const app: Module<AppModuleState, StoreState> = {
  namespaced: true,
  state: {
    dictMap: {},
    // categoryTree: [],
  },
  getters: {},
  mutations: {
    setDictMap(state, data) {
      state.dictMap = { ...data };
    },
    // setCategoryTree(state, categoryTree) {
    //   state.categoryTree = categoryTree;
    // },
  },
  actions: {
    // 初始化操作
    async initData({ commit }) {
      const dictRes = await getDictList();

      const dictMap: Record<string, DictBean> = {};
      if (dictRes.respCode === 200) {
        dictRes.data.forEach((dict) => {
          dictMap[dict.dictCode] = dict;
        });
      }
      commit("setDictMap", dictMap);
      return dictRes;
    },
    // async category({ commit }) {
    //   const res = await getSortList();
    //   let categoryTree: CategoryModel[] = [];
    //   if (res.respCode === 200) {
    //     categoryTree = arr2tree(res.data, "", {
    //       fieldNames: { parentId: "clfId" },
    //     });
    //     // dictRes.data.forEach((dict) => {
    //     //   dictMap[dict.dictCode] = dict;
    //     // });
    //   }
    //   commit("setCategoryTree", categoryTree);
    //   return categoryTree;
    // },
  },
};

export default app;
