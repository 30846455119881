import { UserInfo } from "@/services/login";

// 获取用户信息
export function getUserInfo(): UserInfo {
  const userinfo = localStorage.getItem("_xr_u");
  if (!userinfo) {
    return {} as UserInfo;
  }
  try {
    return JSON.parse(userinfo);
  } catch (e) {
    return {} as UserInfo;
  }
}
// 设置用户信息
export function setUserInfo(userinfo: UserInfo) {
  return localStorage.setItem("_xr_u", JSON.stringify(userinfo));
}
// 清除用户信息
export function clearUserInfo() {
  return localStorage.removeItem("_xr_u");
}
