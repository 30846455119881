/*
 * @Author: zchanghua
 * @Date: 2023-08-13 23:34:11
 * @LastEditors: zchanghua
 * @LastEditTime: 2024-11-13 20:12:09
 * @FilePath: /xrweb/src/configs/const/app.const.ts
 * @Description:
 */
/** 数据字典的code */
export enum DictCode {
  /** 模型类型 */
  XrModType = "xr_mod_type",
  /** 模型类型 */
  UserType = "userType",
  /** 性别 */
  Sex = "sex",
}

// 模型类型
export enum XrModType {
  fbx = "0",
  obj = "1",
  glb = "2",
  gltf = "3",
}

export enum XmaType {
  image = "1",
  video = "2",
  audio = "3",
  identify = "4",
  ab = "5",
  doc = "6",
  points = "7",
}

// 分类类型
export enum XstType {
  model = "0",
  scene = "1",
  space = "2",
  ab = "3",
  material = "4",
}

// Aigc类型
export enum AiType {
  model = "1",
  img = "2",
}
