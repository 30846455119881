import { createStore, createLogger } from "vuex";
import modules from "./modules";
import { StoreState } from "./types";
// const PERSIST_PATHS: string[] = ["user.name"];
const store = createStore<StoreState>({
  strict: process.env.NODE_ENV !== "production",
  mutations: {},
  actions: {},
  modules: { ...modules },
  plugins: process.env.NODE_ENV === "production" ? [createLogger()] : [],
});

export default store;
