//main.js
import { Lock, User, ArrowDown } from "@element-plus/icons-vue";
// Location, Document, Menu as IconMenu, Setting
import type { App } from "vue";
export function registerIcon(app: App): void {
  app.component("IconUser", User);
  app.component("IconLock", Lock);
  app.component("IconArrowDown", ArrowDown);
  // app.component('Location', Location)
  // app.component('Document', Document)
  // app.component('IconMenu', IconMenu)
  // app.component('Setting', Setting)
}
