import axios, { AxiosResponse, AxiosRequestConfig, AxiosError } from "axios";
import { ElMessage } from "element-plus";
import { apiConfig } from "@/configs/app.config";
import { getUserInfo } from "@/utils/authority";
import { ApiResponse } from "@/@types";
import { readFileToJson } from "@/utils/file";
// import { render } from '@testing-library/react'
/**
 * get status code
 * @param {AxiosResponse} response Axios  response object
 */
const getErrorCode2text = (response: AxiosResponse): string => {
  /** http status code */
  const code = response.status;
  /** notice text */
  let message = "Request Error";
  switch (code) {
    case 400:
      message = "Request Error";
      break;
    case 401:
      message = "登录失效，清重新登录";
      break;
    case 403:
      message = "拒绝访问";
      break;
    case 404:
      message = "访问资源不存在";
      break;
    case 408:
      message = "请求超时";
      break;
    case 500:
      message = "未知错误";
      break;
    case 501:
      message = "承载服务未实现";
      break;
    case 502:
      message = "网关错误";
      break;
    case 503:
      message = "服务暂不可用";
      break;
    case 504:
      message = "网关超时";
      break;
    case 505:
      message = "暂不支持的 HTTP 版本";
      break;
    default:
      message = "未知错误";
  }
  return message;
};

class ApiError {
  public code = 0;
  public message = "系统异常，请稍后再试";
  constructor({ code = -999, message = "系统异常，请稍后再试" }) {
    this.code = code;
    this.message = message;
  }
}
/**
 * @returns  {AxiosResponse} result
 * @tutorial see more:https://github.com/onlyling/some-demo/tree/master/typescript-width-axios
 * @example
 * service.get<{data: string; code: number}>('/test').then(({data}) => { console.log(data.code) })
 */
const service = axios.create({
  // baseURL: apiConfig.baseUrl,
  timeout: 30000,
  headers: {},
});
/**
 * @description 请求发起前的拦截器
 * @returns {AxiosRequestConfig} config
 */
service.interceptors.request.use(async (config: AxiosRequestConfig) => {
  // 如果是获取token接口：
  if (
    !config.url?.startsWith("/mockapi/") &&
    !config.url?.startsWith("/opapi/") &&
    !config.url?.startsWith("http")
  ) {
    config.baseURL = apiConfig.baseUrl;
  }

  const userinfo = getUserInfo();
  // console.log(userinfo)
  config.headers = {
    ...(config.headers || {}),
    "X-AUTH-TOKEN": userinfo.token || "",
  };
  return config;
});

/**
 * @description 响应收到后的拦截器
 * @returns {}
 */
service.interceptors.response.use(
  /** 请求有响应 */
  async (response: AxiosResponse) => {
    // console.log('response', response)
    if (response.status === 200) {
      const responseData = response.data as ApiResponse;
      // TODO  200状态下的一些特殊处理,暂时就只对401进行处理
      if (responseData.respCode === 401) {
        ElMessage.error("登录凭证已过期，请重新登录");
        setTimeout(() => {
          window.location.replace("/login");
        });
        return Promise.reject(new ApiError({ message: "401: 请重新登录" }));
      }
      // 如果是个blob流，我这边特殊处理下，把源流文件信息也返回出去
      if (response.config.responseType === "blob") {
        if (response.data.type === "application/json") {
          try {
            const res = await readFileToJson(response.data);
            return Promise.resolve(res);
          } catch (e) {
            return Promise.resolve(e);
          }
        }
        console.log("responseresponse", response);
        // 沿用后台返回的文件名
        const fileNameMatch =
          response.headers["content-disposition"].split("=");
        let fileName = "";
        if (
          fileNameMatch &&
          Array.isArray(fileNameMatch) &&
          fileNameMatch.length > 1
        ) {
          fileName = window.decodeURIComponent(fileNameMatch[1]);
          const alginMatch = fileName.match(/''(.*)/);
          if (
            alginMatch &&
            Array.isArray(alginMatch) &&
            alginMatch.length > 1
          ) {
            fileName = alginMatch[1];
          }
        }
        return Promise.resolve({
          code: 0,
          data: {
            fileName,
            blob: response.data,
          },
        });
      } else {
        return Promise.resolve(response.data);
      }
    } else {
      // response.statusText
      const __text = getErrorCode2text(response);
      return Promise.reject(new ApiError({ message: __text }));
    }
  },
  /** 请求无响应 */
  (error: AxiosError) => {
    console.log("错误信息提示", error);
    // 错误信息的回调统一后期等api规范定好后再看怎么处理
    let errMsg: string = error.message || "";
    if (error.message) {
      errMsg = error.message;
    }
    if (error.response && error.response.status) {
      const __text = getErrorCode2text(error.response);
      errMsg = __text;
    }
    // timeout
    if (errMsg && errMsg.indexOf("timeout") >= 0) {
      errMsg = "timeout";
      ElMessage.error("请求超时");
      return Promise.reject(new ApiError({ message: "接口请求超时" }));
    }

    if (error?.response?.status === 401) {
      // TODO 这边跳转登录页面
      ElMessage.error("登录凭证已过期，请重新登录");
      setTimeout(() => {
        window.location.replace("/login");
      });
      return Promise.reject(new ApiError({ message: "401: 请重新登录" }));
    }
    const apiError = new ApiError({ message: errMsg });
    ElMessage.error(apiError.message);
    return Promise.reject(apiError);
  }
);

// TODO 暂时我们不做扩展，直接返回axios对象
export default service;
